import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { BulletList, BulletListItem } from '@pafcloud/base-components';
import type { GameItemHero_data$key } from './__generated__/GameItemHero_data.graphql';
import { HeroGame } from './hero-game/HeroGame';
import { HeroColors } from './hero-colors';
import { HeroContentWrapper } from './hero-content-blocks/HeroContentWrapper';
import { HeroContentBlocks } from './hero-content-blocks/HeroContentBlock';

const dataFragment = graphql`
  fragment GameItemHero_data on GameItemHero {
    gameTags
    playButtonText
    game {
      ...HeroGame_data
    }

    contentBlocks {
      ...HeroContentBlock_contentBlocks
    }
  }
`;

const Center = styled.div({
  textAlign: 'center',
});

const GameTags = styled(BulletList)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap',
  margin: 'auto',
  padding: 16,
  maxWidth: '500px',
});

const GameTag = styled(BulletListItem)({
  backdropFilter: 'blur(8px)',

  '::before, ::after': {
    boxShadow: `inset 0 0 0 16px ${HeroColors.Text}`,
  },
});

type GameItemHeroComponentProps = {
  data: GameItemHero_data$key;
  loading?: 'lazy' | 'eager';
  onHeroClick: () => void;
};

const GameItemHero: FC<GameItemHeroComponentProps> = ({ data, loading, onHeroClick }) => {
  const { game, gameTags, playButtonText, contentBlocks } = useFragment(dataFragment, data);

  const tags = gameTags.length > 0 ? gameTags : null;

  return (
    <Center>
      <HeroGame data={game} buttonText={playButtonText} loading={loading} onHeroClick={onHeroClick} />
      <HeroContentWrapper>
        {tags != null ? (
          <GameTags>
            {tags.map((tag, index) => (
              <GameTag key={index}>{tag}</GameTag>
            ))}
          </GameTags>
        ) : null}
        <HeroContentBlocks contentBlocks={contentBlocks} onHeroClick={onHeroClick} loading={loading} />
      </HeroContentWrapper>
    </Center>
  );
};

export default GameItemHero;
