import styled from '@emotion/styled';

export const HeroContentWrapper = styled.div({
  margin: 'auto 0',
  zIndex: 1,
  textAlign: 'inherit',

  ':empty': {
    display: 'none',
  },
});
